.App {
  height: 100%;
}

.AppHeaderContainer {
  width: 100%;
  height: 10%;
}

.AppContentContainer {
  background-color: var(--color-white);
  width: 100%;
  height: 90%;
}
