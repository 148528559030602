.Header {
  background-color: var(--color-primary-dark);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.HeaderLogo {
  height: 5rem;
  pointer-events: none;
}
