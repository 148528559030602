.MapInfoContainer {
  margin: 1rem;
  position: absolute;
  left: 0;
  top: 6rem;
  max-width: 100%;
}

.MapInfoTextBackground {
  background-color: var(--color-black-opacity);
  padding: 1rem 2rem;
  border-radius: 4rem;
}

.MapInfoText {
  color: var(--color-white);
  text-align: center;
}

.RefreshButton {
  position: absolute;
  right: auto;
  left: 0;
  margin: 1rem;
  padding: 1.2rem;
  background-color: var(--color-white);
  border-radius: 12px;
  border: none;
}

.RefreshButton:active {
  outline: none;
  background-color: var(--color-medium-grey);
}

@media only screen and (min-width: 68.75em) {
  .MapInfoContainer {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    max-width: 70%;
  }

  .RefreshButton {
    left: auto;
    right: 0;
  }

  .RefreshButton:hover {
    outline: none;
    cursor: pointer;
    background-color: var(--color-light-grey);
  }

  .RefreshButton:active {
    outline: none;
    background-color: var(--color-medium-grey);
  }

  .RefreshButton:focus {
    outline: none;
  }
}
