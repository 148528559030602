.Button {
  background-color: var(--color-primary-dark);
  border: solid 1px var(--color-primary-dark);
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: 0.15s linear;
}

.Button:active {
  outline: none;
  background-color: var(--color-primary-medium);
  border-color: var(--color-primary-medium);
}

.ButtonText {
  padding: 1rem 2.5rem;
  transition: 0.15s linear;
}

@media only screen and (min-width: 68.75em) {
  .Button:hover {
    outline: none;
    cursor: pointer;
    background-color: var(--color-white);
  }

  .Button:focus {
    outline: none;
    box-shadow: 0 1px 8px var(--color-primary-medium);
  }

  .Button:hover .ButtonText {
    color: var(--color-primary-dark);
  }

  .Button:active .ButtonText {
    color: var(--color-white);
  }
}
