* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: inherit;
}

:root {
  --color-primary: #64a70b;
  --color-primary-medium: #558983;
  --color-primary-dark: #0f4030;
  --color-secondary: #ffc72c;
  --color-black: #0c2128;
  --color-white: #fff;
  --color-black-opacity: rgba(0, 0, 0, 0.6);
  --color-light-grey: #EEF3EF;
  --color-medium-grey: #CFD9D6;
  --color-dark-grey: #A6B6B2;
}

/*
Media queries cannot use variables like colors :(
Use one of these values for all media queries:
 - mobile-breakpoint: 37.5em - 600px
 - tablet-breakpoint: 68.75em - 1100px
*/

html {
  box-sizing: border-box;
  outline: 0;
  font-size: 50%; /* Use a bit smaller text on all small screens */
}
@media only screen and (min-width: 68.75em) {
  html {
    font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  color: var(--color-white);
}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-black);
}

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-white);
}

p {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-black);
}
