.ModalContent {
  width: 34rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: none;
  background-color: var(--color-white);
  padding: 4rem;
  text-align: center;
}

.ModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-black-opacity);
}
